<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
        <div class="card card-body">
            <div class="form-group">
                <label for="">عرض من تجاوز تأخره عدد ايام</label>
                <select class="form-control" v-model="limit">
                    <option :value="null">الكل</option>
                    <option value="3">3</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                </select>
            </div>
            <div class="form-group">
              <label for="">الفترة من</label>
              <input type="date"
                class="form-control" v-model="from">
            </div>
            <div class="form-group">
              <label for="">الفترة الى</label>
              <input type="date"
                class="form-control" v-model="to">
            </div>
            <div class="form-group">
                <span>اختر الفصل</span>
                <select class="form-control" v-model="classroom">
                    <option value="">-- اختر --</option>
                    <option v-for="c in classes" :key="c._id" :value="c.classname + ',' + c.classroom">
                        {{ c.classname }} - {{ c.classroom }}
                    </option>
                </select>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="search()">
                    <i class="fa fa-search"></i>
                    عرض الطلاب
                </button>
                <br><br>
                <div class="form-group" v-if="edutmes">
                  <label for="">نص الرسالة</label>
                  <textarea class="form-control" v-model="message" @change="messageChanged()" rows="5"></textarea>
                </div>
                <a href="javascript:;" @click="edutmes = !edutmes">
                    <i class="fa fa-comment"></i> تعديل نص الرسالة
                </a>
            </div>
            <div class="col-12 text-center g" v-if="!loaded2">
                <img :src="require('@/assets/images/loading.svg')" style="width: 50px;"> جاري الاستعلام... ربما تستغرق دقيقة.
            </div>
        </div>
    </div>
    <div class="col-12" v-if="loaded">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-striped table-sm">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            اجمالي ايام التأخر	
                        </th>
                        <th>
                            الانذار الاول (3 أيام)	<button class="fa fa-print2 btn btn-sm btn-warning"  @click="print(3, reports.filter(function(x){
                                return x.reports[2].length >= 3 ? x : false
                            }))"><i class="fa fa-print"></i> </button>
                            <b-dropdown dropbottom no-caret 
                                id="dropdown-1" 
                                :html="`<i class='fa fa-whatsapp'></i> `"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="success"
                            >
                            <b-dropdown-item @click="sendall('wa', 3, reports.filter(function(x){
                                return x.reports[2].length >= 3 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                اداة المتصفح
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('whatsapp', 3, reports.filter(function(x){
                                return x.reports[2].length >= 3 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                واتس ذكي
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('sms', 3, reports.filter(function(x){
                                return x.reports[2].length >= 3 ? x : false
                            }))">
                                <i class="fa fa-comment"></i>
                                رسالة نصية
                            </b-dropdown-item>
                            </b-dropdown>
                        </th>
                        <th>
                            الانذار الثاني (5 أيام) <button class="fa fa-print2 btn btn-sm btn-warning"  @click="print(5, reports.filter(function(x){
                                return x.reports[2].length >= 5 ? x : false
                            }))"> <i class="fa fa-print"></i></button>
                            <b-dropdown dropbottom no-caret 
                                id="dropdown-1" 
                                :html="`<i class='fa fa-whatsapp'></i> `"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="success"
                            >
                            <b-dropdown-item @click="sendall('wa', 5, reports.filter(function(x){
                                return x.reports[2].length >= 5 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                اداة المتصفح
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('whatsapp', 5, reports.filter(function(x){
                                return x.reports[2].length >= 5 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                واتس ذكي
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('sms', 5, reports.filter(function(x){
                                return x.reports[2].length >= 5 ? x : false
                            }))">
                                <i class="fa fa-comment"></i>
                                رسالة نصية
                            </b-dropdown-item>
                            </b-dropdown>
                        </th>
                        <th>
                            الانذار الثالث (10 أيام) <button class="fa fa-print2 btn btn-sm btn-warning"  @click="print(10, reports.filter(function(x){
                                return x.reports[2].length >= 10 ? x : false
                            }))"><i class="fa fa-print"></i> </button>
                            <b-dropdown dropbottom no-caret 
                                id="dropdown-1" 
                                :html="`<i class='fa fa-whatsapp'></i> `"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="success"
                            >
                            <b-dropdown-item @click="sendall('wa', 10, reports.filter(function(x){
                                return x.reports[2].length >= 10 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                اداة المتصفح
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('whatsapp', 10, reports.filter(function(x){
                                return x.reports[2].length >= 10 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                واتس ذكي
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('sms', 10, reports.filter(function(x){
                                return x.reports[2].length >= 10 ? x : false
                            }))">
                                <i class="fa fa-comment"></i>
                                رسالة نصية
                            </b-dropdown-item>
                            </b-dropdown>
                        </th>
                        <th>
                            الانذار الرابع (15 يوم) <button class="fa fa-print2 btn btn-sm btn-warning"  @click="print(15, reports.filter(function(x){
                                return x.reports[2].length >= 15 ? x : false
                            }))"> <i class="fa fa-print"></i></button>
                            <b-dropdown dropbottom no-caret 
                                id="dropdown-1" 
                                :html="`<i class='fa fa-whatsapp'></i> `"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="success"
                            >
                            <b-dropdown-item @click="sendall('wa', 15, reports.filter(function(x){
                                return x.reports[2].length >= 15 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                اداة المتصفح
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('whatsapp', 15, reports.filter(function(x){
                                return x.reports[2].length >= 15 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                واتس ذكي
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('sms', 15, reports.filter(function(x){
                                return x.reports[2].length >= 15 ? x : false
                            }))">
                                <i class="fa fa-comment"></i>
                                رسالة نصية
                            </b-dropdown-item>
                            </b-dropdown>
                        </th>
                        <th>
                            الانذار الخامس (20 يوم) <button class="fa fa-print2 btn btn-sm btn-warning"  @click="print(20, reports.filter(function(x){
                                return x.reports[2].length >= 20 ? x : false
                            }))"> <i class="fa fa-print"></i></button>
                            <b-dropdown dropbottom no-caret 
                                id="dropdown-1" 
                                :html="`<i class='fa fa-whatsapp'></i> `"
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                size="sm"
                                variant="success"
                            >
                            <b-dropdown-item @click="sendall('wa', 20, reports.filter(function(x){
                                return x.reports[2].length >= 20 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                اداة المتصفح
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('whatsapp', 20, reports.filter(function(x){
                                return x.reports[2].length >= 20 ? x : false
                            }))">
                                <i class="fa fa-whatsapp"></i>
                                واتس ذكي
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendall('sms', 20, reports.filter(function(x){
                                return x.reports[2].length >= 20 ? x : false
                            }))">
                                <i class="fa fa-comment"></i>
                                رسالة نصية
                            </b-dropdown-item>
                            </b-dropdown>
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="report in reports" :key="report._id">
                            <td>
                                {{ report.name }}
                            </td>
                            <td>
                                {{ report.reports[2].length }}
                            </td>
                            <td v-for="i in [3,5,10,15,20]" :key="i">
                                <template v-if="report.reports[2].length >= i">
                                    <div class="btn-group btn-group-sm">
                                        <button type="button" class="btn btn-warning" @click="print(i, [report]);" @mouseover="hovers['o'+i] = true; hover()" @mouseleave="hovers['o'+i] = false; hover()">
                                            <i class="fa fa-print2"></i>
                                            <span v-if="hovers['o'+i] || true"> طباعة <i class="fa fa-check-square text-danger" v-if="buttons.includes(report.number + '-' + i + '-print2')"></i></span>
                                        </button>
                                        <div @mouseover="hovers['t'+i] = true; hover()" @mouseleave="hovers['t'+i] = false; hover()">
                                            <b-dropdown dropbottom no-caret 
                                                id="dropdown-1" 
                                                :html="hovers['t'+i] || true ? `<i class='fa fa-whatsapp'></i> ارسال لولي الامر ` + (buttons.includes(report.number + '-' + i + '-send2') ? `<i class='fa fa-check-square text-danger'></i>` : '')  : `<i class='fa fa-whatsapp'></i>` "
                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                size="sm"
                                                variant="success"
                                            >
                                                <b-dropdown-item @click="sendWAModal([{phones: report.phones, message: generateAlertMessage(i,report)}]); buttonClick(report.number + '-' + i + '-send2')">
                                                    <i class="fa fa-whatsapp"></i>
                                                    اداة المتصفح
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="send([{phones: report.phones, message: generateAlertMessage(i,report)}], 'whatsapp'); buttonClick(report.number + '-' + i + '-send2')">
                                                    <i class="fa fa-whatsapp"></i>
                                                    واتس ذكي
                                                </b-dropdown-item>
                                                <b-dropdown-item @click="send([{phones: report.phones, message: generateAlertMessage(i,report)}], 'sms'); buttonClick(report.number + '-' + i + '-send2')">
                                                    <i class="fa fa-comment"></i>
                                                    رسالة نصية
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <button type="button" class="btn btn-primary dnone" @click="forward(i, report)" @mouseover="hovers['th'+i] = true; hover()" @mouseleave="hovers['th'+i] = false; hover()">
                                            <i class="fa fa-share"></i>
                                            <span v-if="hovers['th'+i] || true"> تحويل للوكيل</span>
                                        </button>
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem
  },
  directives: {
    Ripple
  },
    data(){
        return {
            edutmes: false,
            loaded: false,
            loaded2: true,
            limit: null,
            from: "2024-11-17",
            to: new Date().toISOString().split("T")[0],
            user: JSON.parse(localStorage.getItem('user')),
            reports: [],
            hovers: {},
            classes: [],
            classroom: "",
            buttons: [],
            message: localStorage.getItem('alerts-message') ?? `ولي امر الطالب {name}
برجاء العلم ان بنكم تجاوز عدد ايام تأخره بدون عذر {count} ايام
ايام التأخر:
{days}
برجاء التواصل مع ادارة المدرسة لمراجعة الانذارات`
        }
    },
    created(){
        if(!checkPer("students|alerts")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push({
                            classname: a.title,
                            classroom: b
                        })
                    })
                })
            }
        }).fail(function(){
        })
    },
    methods: {
        messageChanged(){
            localStorage.setItem('alerts-message', this.message)
        },
        buttonClick(value){
            var g = this;
            $.post(api + '/user/students/reports/alert-buttons/save', {
                jwt: this.user.jwt,
                value: value
            }).then(function(r){
                g.buttons.push(value)
            })
        },
        search(){
            var g = this;
            g.reports = [];
            if(!g.classroom || g.classroom == ""){
                return alert("برجاء اختيار الفصل", 200);
            }
            g.loaded2 = false;
            $.post(api + '/user/students/reports/alert-buttons', {
                jwt: this.user.jwt,
            }).then(function(r){
                g.buttons = r.response
            })
            $.post(api + '/user/students/reports/between', {
                jwt: this.user.jwt,
                from: this.from,
                to: this.to,
                limit: this.limit,
                classname: g.classroom.replace(",", '-')
            }).then(function(r){
                g.loaded2 = true;
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    r.response.forEach(function(a){
                        if(a.reports[2].length >= 3){
                            if(!g.limit || a.reports[2].length >= g.limit){
                                g.reports.push(a)
                            }
                        }
                    })
                }
            }).fail(function(){
                alert("حدث خطأ")
            })
        },
        hover(){
            this.hovers = JSON.parse(JSON.stringify(this.hovers))
        },
        print(count, students){
            var g = this;
            var html = "";
            var cc = new Number(count)
            var myWindow=window.open('','','');
            students.forEach(function(student){

            g.buttonClick(`${student.number}-${cc}-print2`)
            var days = "";
            student.reports[2].forEach(function(a, i){
                if(i+1 <= cc){
                    days = days + `
                    <tr>
                        <td>${i+1}</td>
                        <td>${a.date}</td>
                    </tr>
                    `;
                }
            })
            
            if(count == 3){
                count = 1;
            }
            if(count == 5){
                count = 2;
            }
            if(count == 10){
                count = 3;
            }
            if(count == 15){
                count = 4;
            }
            if(count == 20){
                count = 5;
            }

            if(count == 1){
                var text = `
                <h5>
                     الطالب/ ${student.name}
                </h5>
                <p>
                    نود تنبيهك بان مجموع ايام تأخرك قد بلغ 3 ايام
                    , لذا نرجو منك الاهتمام بالانتظام في الحضور الى المدرسة حيث
                    ان تأخرك يؤثر على تحصيلك الدراسي وتدني مستواك بين اقرانك
                    لذا نرجو منك الحرص على عدم التأخر وانضباطك
                    في الحضور للمدرسة.
                    <br><br>
                    اسم الطالب: .............................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    التاريخ: &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; / &nbsp; &nbsp;&nbsp;&nbsp;/ 1446هـ
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    التوقيع: .........................................
                </p>
                `
            }
            if(count == 2){
                var text = `<h5>
                    ولى امر الطالب/ ${student.name} &nbsp;&nbsp;&nbsp; حفظه الله
                </h5>
                <p>
                    نود احاطتكم بأن مجموع ايام تأخر ابنكم/بنتكم قد بلغ 5 ايام
                    ٫ لذا نرجو منك الاهتمام بمتابعة ابنكم/بنتكم وحثه على الانتظام في حضور
                    المدرسة حيث ان تأخره يؤثر على تحصيله
                    الدراسي وتدني مستواه بين اقرانه لذا نرجو منكم الحرص
                    على توجيه الطالب وانضباطه في الحضور للمدرسة.
                    <br><br>
                    تم اشعار ولي الامر: &nbsp;&nbsp;
                    [&nbsp;] برسالة نصية &nbsp;&nbsp;
                    [&nbsp;] خطاب &nbsp;&nbsp;
                    [&nbsp;] اخرى: .................... &nbsp;&nbsp;
                    التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
                </p>`
            }
            if(count == 3){
                var text = `<h5>ولي أمر الطالب: ${student.name}</h5>
            <p>
                نود احاطتكم بأن مجموع ايام تأخر ابنكم/بنتكم قد بلغ 10 ايام
               
            ٫
            لذا نرجو منك مراجعة المدرسة لاتخاذ الاجراء المناسب ومعرفة مسببات التأخر 

            <br><br>
            اسم ولي الامر: ..............................................&nbsp;&nbsp;
                التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
            &nbsp;&nbsp;
            توقيع ولي الامر:......................
            <br><br>
            وكيل شؤون الطلاب:.................................................&nbsp;&nbsp;
            مدير المدرسة:..............................................
            </p>`
            }
            if(count == 4){
                var text = `<h5>ولي أمر الطالب: ${student.name}</h5>
            <p>
                نود احاطتكم بأن مجموع ايام تأخر ابنكم/بنتكم قد بلغ 15 يوم
               
            ٫
            لذا نرجو منك مراجعة المدرسة لاتخاذ الاجراء المناسب ومعرفة مسببات التأخر 

            <br><br>
            اسم ولي الامر: ..............................................&nbsp;&nbsp;
                التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
            &nbsp;&nbsp;
            توقيع ولي الامر:......................
            <br><br>
            وكيل شؤون الطلاب:.................................................&nbsp;&nbsp;
            مدير المدرسة:..............................................
            </p>`
            }
            if(count == 5){
                var text = `<h5>ولي أمر الطالب: ${student.name}</h5>
            <p>
                نود احاطتكم بأن مجموع ايام تأخر ابنكم/بنتكم قد بلغ 20 يوم
               
            ٫
            لذا نرجو منك مراجعة المدرسة لاتخاذ الاجراء المناسب ومعرفة مسببات التأخر 

            <br><br>
            اسم ولي الامر: ..............................................&nbsp;&nbsp;
                التاريخ: &nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/1446
            &nbsp;&nbsp;
            توقيع ولي الامر:......................
            <br><br>
            وكيل شؤون الطلاب:.................................................&nbsp;&nbsp;
            مدير المدرسة:..............................................
            </p>`
            }
            html = html + (`
                    <div class='container-fluid'>
                        <div class='row'>
                            <div class='col-12 g'>
                                <div style='border: 2px solid #333; border-radius: 3px' class='g'>
                                    <div class='row'>
                                        <div class='col-4 text-center'>
                                            <img src='https://cdn-static.brmja.com/storage/scrapped/6319866ea8712498504411.jpg' style='width: 160px'>
                                            <br>
                                            وزارة التعليم
                                            <br>
                                            ${g.user.name}
                                        </div>
                                        <div class='col-4 text-center'>
                                            <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 160px'>
                                        </div>
                                        <div class='col-4'>
                                            <br>
                                            العام: 1446هـ
                                            <br>
                                            الفصل الدراسي: الثاني
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='col-4 c g'>
                                <div class='text-center'>
                                    <h4 style='border: 1px dashed #999; border-radius: 3px' class='g'>
                                         إنذار تأخر طالب
                                    </h4>
                                </div>
                            </div>
                            <div class='col-12'></div>
                            <div class='col-6 c g'>
                                <div class='text-center'>
                                    <span style='width: 100%' class='g'>
                                        اسم الطالب: ${student.name}
                                    </span>
                                </div>
                            </div>
                            <div class='col-6 c g'>
                                <div class='text-center'>
                                    <span style='width: 100%' class='g'>
                                        الفصل: ${student.classname} - ${student.classroom}
                                    </span>
                                </div>
                            </div>
                            <div class='col-12 g'></div>
                            <div class='col-12' style='border-top: dashed 2px #777'></div>
                            <div class='col-10 c'>
                                <br>
                                <div style=' padding: 10px; border-radius: 2px'>
                                    ايام التأخير
                                    <table class='table table-sm table-bordered table-striped'>
                                        <thead>
                                            <th>
                                                م
                                            </th>
                                            <th>
                                                التاريخ
                                            </th>
                                        </thead>
                                        <tbody>
                                            ${days}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class='col-12'></div>
                            <div class='col-4 c text-center'>
                                <h4 style='padding: 5px; border: solid 1px #333; border-radius: 10px 10px 0px 0px'>
                                    ${count == 1 ? 'الانذار الاول' : (
                                        count == 2 ? 'الانذار الثاني' 
                                        : (
                                        count == 3 ? 'الانذار الثالث' 
                                            : (
                                            count == 4 ? 'الانذار الرابع' 
                                                : 'الانذار الخامس'
                                            )
                                        )
                                    )}
                                </h4>
                            </div>
                            <div class='col-12 g' style='position: relative; top: -12px'>
                                <div style='padding: 10px; border: 1px solid #333; border-radius: 3px;'>${text}</div>
                            </div>
                        </div>
                    </div>
            `);
                
            })
            myWindow.document.write(`
            <html dir='rtl'>
                <head>
                    <link href='https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css' rel="stylesheet">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Alexandria&display=swap" rel="stylesheet">
                    <style>
                        .g{
                            padding: 3px;
                        }
                        *{
                            font-family: 'Alexandria', sans-serif;
                        }
                        .c{
                            margin: 0px auto;
                        }
                        .container-fluid{
                        page-break-after:always;
                        }
                    </style>
                </head>
                <body>
            ${html}
                </body>
            </html>`)
            myWindow.document.close();
            myWindow.focus();
            setTimeout(function(){
                myWindow.print(); 
            }, 500)
        },
        sendall(method, count, students){
            var g = this;
            if(confirm(`متأكد من ارسال الى ${students.length} طالب؟`)){
                students.forEach(function(a){
                    g.buttonClick(a.number + '-' + count + '-send2')
                })
                if(method == 'wa'){
                    g.sendWAModal(students.map(function(x){
                        return {
                            phones: x.phones,
                            message: g.generateAlertMessage(count, x)
                        }
                    }))
                }else{
                    g.send(students.map(function(x){
                        return {
                            phones: x.phones,
                            message: g.generateAlertMessage(count, x)
                        }
                    }), method)
                }
            }
        },
        sendWAModal(arr){
            $("#sendmodalwaarray").val(JSON.stringify(arr))
            $("#sendmodalwatype").val("students")
            $("#sendWAModal").click()
        },
        send(arr, method){
            var g = this;
            $.post(api + '/user/messages/send-arr', {
                jwt: this.user.jwt,
                arr: JSON.stringify(arr),
                method: method,
                type: 'students'
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الارسال بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        generateAlertMessage(count, report){
            var g = this;
            var message = g.message;

             var days = ""
            report.reports[2].forEach(function(vv){
                days = days + `${vv.date}\n`
            })
            message = message?.replace("{name}", report.name)?.replace("{count}", count)?.replace("{days}", days)
            return convertToHijri(message)
        },
    }
}
</script>
